import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Typography is defined as the science of style, appearance, and structure of fonts that aim to provide convenience and aesthetics to the reader. Effective typography depends not only on the content but also on the presentation visual of the typography itself, starting from the font type, font size and line height. You can chek out the guideline for more detail description.`}</p>
    <div className="divi" />
    <h3>{`Usage Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Text("hello")
  .LGN<name>()
`}</code></pre>
    <h3>{`Usage Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Text("hello")
  .font(LGNFont.<name>)
`}</code></pre>
    <div className="divi" />
    <h2>{`Heading`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Hero section, Title content or component, Subtitle of title`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading1'>{`Heading 1`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading2'>{`Heading 2`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading3'>{`Heading 3`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading4'>{`Heading 4`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading5'>{`Heading 5`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading6'>{`Heading 6`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Heading Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.LGNHeading1()
.LGNHeading2()
.LGNHeading3()
.LGNHeading4()
.LGNHeading5()
.LGNHeading6()
`}</code></pre>
    <h3>{`Heading Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.font(LGNFont.heading1)
.font(LGNFont.heading2)
.font(LGNFont.heading3)
.font(LGNFont.heading4)
.font(LGNFont.heading5)
.font(LGNFont.heading6)
`}</code></pre>
    <div className="divi" />
    <h2>{`Body`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Paragraph, Button, Text Field, Tabs, etc`}</p>
    <h3>{`Body Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.LGNBodyLarge()
.LGNBodySmall()
`}</code></pre>
    <h3>{`Body Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.font(LGNFont.bodyLargeRegular)
.font(LGNFont.bodyLargeSemiBold)
.font(LGNFont.bodyLargeBold)
.font(LGNFont.bodyLargeItalic)
.font(LGNFont.bodyMediumRegular)
.font(LGNFont.bodyMediumSemiBold)
.font(LGNFont.bodyMediumBold)
.font(LGNFont.bodyMediumItalic)
.font(LGNFont.bodySmallRegular)
.font(LGNFont.bodySmallSemiBold)
.font(LGNFont.bodySmallBold)
.font(LGNFont.bodySmallItalic)
`}</code></pre>
    <div className="divi" />
    <h2>{`Caption`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Tags, Chips, Badge, etc`}</p>
    <h3>{`Caption Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.LGNCaptionLarge()
.LGNCaptionSmall()
`}</code></pre>
    <h3>{`Caption Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.font(LGNFont.bodyCaptionRegular)
.font(LGNFont.bodyCaptionSemiBold)
.font(LGNFont.bodyCaptionBold)
.font(LGNFont.bodyCaptionItalic)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      